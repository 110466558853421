import { combineReducers } from "redux"
import lillyBreastCancerAssessmentExtractReducer from "./lillybreastcancerassessmentextract/reducer"

export interface LillyBreastCancerAssessmentExtractState {
	lillyBreastCancerAssessmentExtract: LillyBreastCancerAssessmentExtractState
}

const lillyBreastCancerReducer = combineReducers({
	lillyBreastCancerAssessmentExtract:
		lillyBreastCancerAssessmentExtractReducer
})

export default lillyBreastCancerReducer
